@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@500&family=Merriweather&family=Poppins:wght@400;500&display=swap");

* {
	font-family: "Poppins", sans-serif;
	box-sizing: border-box;
	transition: all 0.2s ease-out;
}

body {
	overflow: overlay;
	min-height: 100vh;

	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#root {
	min-height: 100vh;
	min-width: 320px;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

button,
input,
optgroup,
select,
textarea,
h1,
h2,
h3,
p {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	color: inherit;
}
