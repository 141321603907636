.main {
	background: #151515;
	position: absolute;
	inset: 3rem 0 0 0;
	overflow: auto;
	min-width: 320px;

	> .images-container {
		padding: 2rem;
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
		gap: 2rem;
	}
}

.styled-scrollbar {
	&::-webkit-scrollbar {
		-webkit-appearance: none;
	}

	&::-webkit-scrollbar:vertical {
		width: 10px;
	}

	&::-webkit-scrollbar:horizontal {
		height: 10px;
	}

	&::-webkit-scrollbar-button:increment {
		display: none;
	}

	&::-webkit-scrollbar-button {
		display: none;
	}

	&::-webkit-scrollbar-thumb {
		background: #ec1d24cf;
		border-radius: 20px;
		border: 2px solid #fff;
	}

	&::-webkit-scrollbar-track {
		border-radius: 10px;
	}
}

.card {
	position: relative;
	cursor: pointer;

	> a {
		$margin: 0.3rem;
		text-decoration: none;

		position: absolute;
		margin: $margin;
		height: calc(100% - ($margin * 2));
		width: calc(100% - ($margin * 2));
		background: linear-gradient(#000000, #00000000);
		padding: 1rem;
		transition: opacity 0.5s;
		opacity: 0;

		&:active,
		&:hover {
			opacity: 1;
		}

		> h1,
		> p {
			text-align: center;
		}

		> h1 {
			color: #e0b775cf;
			font-weight: 500;
			font-size: 3rem;
		}

		> p {
			color: #ffffffde;
			font-weight: 400;
			font-size: 1.5rem;
		}
	}

	> img {
		padding: 0.2rem;
		border: #ffffff9e 2px solid;
		box-shadow: 2px 2px 4px 0px #ec1d2454;
		border-radius: 2px;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
